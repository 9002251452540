.no-outline {
  outline: none;

  &:active, &:focus {
    outline: none;
  }
}

.pagination-wrap, ws-pagination {
  min-height: 30px !important;
}

input, textarea {
  border-bottom: 1px solid #aaa !important;
}

.table-wrap {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: auto;
}

.fix-table-header {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 50px;
  text-transform: uppercase;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e1e1e1;
  justify-content: start;
  align-items: center;
  z-index: 1;

  &__item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    color: #757575;
    text-align: left;
    padding: 0 8px 0 8px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    &:first-of-type {
      padding-left: 24px;
    }
  }
}

.c-ws-table {
  width: 100%;
  max-width: 100%;

  md-icon {
    max-height: 17px;
    min-height: inherit;
    font-size: 17px;
    color: #979797;
    min-height: 17px;
  }

  thead {
    text-transform: uppercase;
    background-color: #f5f5f5;
    border-bottom: 1px solid #e1e1e1;
  }

  th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    color: #757575;
    text-align: left;
    padding: 0 8px 0 8px;
    font-weight: 600;

    &:first-of-type {
      padding-left: 24px;
    }
  }

  td {
    font-size: 12px;
    color: #212121;
    padding: 16px 8px 16px 8px;
    border-bottom: 1px solid #e1e1e1;
    vertical-align: top;

    &:first-of-type {
      padding-left: 24px;
    }

    &:last-of-type {
      padding-right: 24px;
    }
  }

  tbody {
    tr {
      background-color: #fff;

      &:hover {
        background-color: #f5f5f5;
      }

      &.select {
        background-color: #2196f3;
      }
    }
  }

  &--clicable {
    tr {
      cursor: pointer;
    }
  }
}

.span-a {
  color: #2196f3;

  &:hover {
    color: #2196f3;
    text-decoration: underline;
  }
}

md-select-menu {
  .select-header {
    display: flex;
    position: relative;
    align-items: center;
    width: auto;
    height: 48px;
    padding-left: 10px;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;

    input {
      width: 100%;
      height: 100%;
      padding: 0;
      border: 0;
      outline: none;
    }
  }

}