.shipment-info {
  padding: 0 24px;
  margin: 24px 0 12px 0;
}

.shipment-sort-icon {
  margin-left: 0;
  margin-right: 24px;
}

.filter-wrap {
  padding: 10px 24px 0 24px;
  box-sizing: content-box;

  md-input-container {
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    .md-errors-spacer {
      display: none !important;
    }
  }
}

.icon-clear-filter {
  margin-right: 14px !important;
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
  font-size: 18px;
  color: #757575;
}

.ttn-filter {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  max-width: 150px !important;

  .md-errors-spacer {
    display: none !important;
  }
}

.comment-input {
  margin: 0 !important;
  border: 0 !important;
  padding: 0 !important;

  textarea, input {
    line-height: 15px;
    border: 1px solid #e0e0e0 !important;
    border-radius: 3px;
    padding: 3px !important;
    box-sizing: content-box;
    margin-bottom: 5px;
  }

  input {
    min-width: 55px;
  }

  .md-errors-spacer {
    display: none !important;
  }

  &__debt {
    input {
      line-height: 15px;
      border: 1px solid #e0e0e0 !important;
      border-radius: 3px;
      padding: 2px !important;
      box-sizing: content-box;
      margin-bottom: 5px;
      max-width: 60px !important;
      min-width: 60px !important;
    }
  }

  &__comment {
    textarea {
      max-width: 200px !important;
      min-width: 200px !important;
    }
  }

  &__comment--large {
    textarea {
      max-width: 300px !important;
      min-width: 300px !important;
    }
  }

  &__kara {
    input {
      max-width: 20px !important;
      min-width: 20px !important;
    }
  }
}

.tr-control {
  background: #ffaca7;

  td {
    background: #ffaca7;
  }
}

.tr-select {
  td {
    border-top: 3px solid #000 !important;
    border-bottom: 3px solid #000 !important;
  }
}

.date-input {
  margin-right: 30px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  .md-errors-spacer {
    display: none !important;
  }

  md-icon {
    margin-left: 120px !important;
  }
}

.info-input {
  margin: 20px 0 0 0 !important;

  .md-errors-spacer {
    display: none !important;
  }
}

.control-filter {
  margin-top: 12px !important;
  margin-right: 30px !important;
}

.shipment-parent-info {
  margin-left: 20px;
}

.table-info {
  white-space: nowrap;
  margin-bottom: 4px;

  &__title {
    color: #9e9e9e;
  }
}

.select-user {
  margin: 15px 24px 5px 24px;
}

.filter-icon {
  margin: 10px 20px 0 0;

  &--active {
    color: #dd2c00;
  }
}

.component-title {
  font-size: 20px;
  font-weight: 600;
  height: 50px;
  padding: 0 24px;
}

.action-icon {
  margin: 0 24px 0 0;
}

.dialog-content {
  padding: 24px;
}

.simple-input {
  margin: 0 !important;

  &--with-margin {
    margin: 10px 0 !important;
  }

  .md-errors-spacer {
    display: none;
  }
}

.auth-input {
  margin: 0 0 24px 0 !important;

  .md-errors-spacer {
    display: none;
  }
}

md-tab-content > div {
  flex-direction: column;
  flex-grow: 1;
}

.height-100 {
  height: 100%;
}

.log-audit-icon {
  margin-left: 10px !important;
}