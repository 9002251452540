.ch-tree{

  .tree-item {
    &.selected {
      background-color: #C8EBFB;
      color: #335faf;
    }
  }

  .element {
    outline: inherit;
    padding: 10px;
    font-size: 15px;
    color: #000;

    .title{
      width: 100%;
    }

  }

  .element-a {
    width: 100%;
    height: 40px;
    padding: 10px 10px 10px 20px;

    .element {
      padding: 0;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .toggle-button {
    margin-right: 10px;
  }

}
