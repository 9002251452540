.login-wrap {
  margin: 0 12px;
  padding: 24px;
  background: #f6f6f6;
  border-radius: 2px;

  &__title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  .auth-button {
    padding: 0 !important;
    margin: 0 0 24px 0 !important;
  }
}