.shipment-info {
  padding: 0 24px;
  margin: 24px 0 12px 0;
}

.shipment-sort-icon {
  margin-left: 0;
  margin-right: 24px;
}

.filter-wrap {
  padding: 10px 24px 0 24px;
  box-sizing: content-box;

  md-input-container {
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    .md-errors-spacer {
      display: none !important;
    }
  }
}

.icon-clear-filter {
  margin-right: 14px !important;
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
  font-size: 18px;
  color: #757575;

  &--input {
    margin-right: 0 !important;
    margin-left: 5px !important;
  }
}

.ttn-filter {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  max-width: 150px !important;

  .md-errors-spacer {
    display: none !important;
  }
}

.comment-input {
  margin: 0 !important;
  border: 0 !important;
  padding: 0 !important;

  textarea, input {
    line-height: 15px;
    border: 1px solid #e0e0e0 !important;
    border-radius: 3px;
    padding: 3px !important;
    box-sizing: content-box;
    margin-bottom: 5px;
  }

  input {
    min-width: 55px;
  }

  .md-errors-spacer {
    display: none !important;
  }

  &--with-icon {
    position: relative;

    textarea, input {
      padding-right: 10px !important;
    }

    md-icon {
      position: absolute;
      right: -10px !important;
      top: 50%;
      transform: translateY(-60%);
    }
  }
}

.tr-control {
  background: #ffaca7;

  td {
    background: #ffaca7;
  }
}

.date-input {
  margin-right: 30px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  .md-errors-spacer {
    display: none !important;
  }

  md-icon {
    margin-left: 120px !important;
  }
}

.info-input {
  margin: 20px 0 0 0 !important;

  .md-errors-spacer {
    display: none !important;
  }
}

.control-filter {
  margin-top: 12px !important;
  margin-right: 30px !important;
}

.shipment-parent-info {
  margin-left: 20px;
}

.table-info {
  white-space: nowrap;
  margin-bottom: 4px;

  &__title {
    color: #9e9e9e;
  }
}