/**
 *  Do not remove the comment below. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
.oni-table {
  border: none;
  border-collapse: collapse;
  width: 100%; }
  .oni-table td, .oni-table th {
    padding: 12px; }
  .oni-table th {
    padding: 45px 12px 30px 12px; }
  .oni-table thead {
    text-transform: uppercase;
    color: #30a5ea;
    font-size: 10px; }
    .oni-table thead th {
      text-align: left; }
  .oni-table tbody tr.tr-in-payment {
    height: 95px;
    padding: 0; }
    .oni-table tbody tr.tr-in-payment td {
      padding: 0; }
    .oni-table tbody tr.tr-in-payment:hover {
      background: #fff;
      color: rgba(0, 0, 0, 0.870588); }
  .oni-table tbody tr {
    padding: 25px; }
    .oni-table tbody tr ng-md-icon {
      fill: #e6eef4; }
    .oni-table tbody tr:hover {
      background: #57677b;
      color: #fff; }
      .oni-table tbody tr:hover ng-md-icon {
        fill: #58bcf1; }
      .oni-table tbody tr:hover td.td-description {
        color: #4eb7f5; }
      .oni-table tbody tr:hover td.td-sans-light {
        color: #fff; }
      .oni-table tbody tr:hover td.td-sans {
        color: #fff; }
    .oni-table tbody tr td {
      border-bottom: 1px solid #eeeff2; }
      .oni-table tbody tr td .td-first {
        width: 100%;
        margin-top: 11px;
        height: 46px;
        line-height: 46px;
        font-family: SofiaProSemiBold;
        color: #465364;
        font-size: 20px;
        overflow: hidden;
        text-overflow: ellipsis; }
      .oni-table tbody tr td .td-second {
        width: 100%;
        height: 25px;
        font-family: OpenSans-Light;
        color: #57677b;
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis; }
        .oni-table tbody tr td .td-second .td-second-circle {
          width: 9px;
          height: 9px;
          border: 2px solid #57c1ff;
          border-radius: 11px;
          margin-top: 5px;
          margin-right: 15px;
          overflow: hidden;
          text-overflow: ellipsis; }
    .oni-table tbody tr td.td-description {
      font-family: SofiaProMedium;
      color: #465364;
      font-size: 19px; }
    .oni-table tbody tr td.td-last {
      border-bottom: 0px solid #eeeff2; }
    .oni-table tbody tr td.td-sans-light {
      font-family: OpenSans-Light;
      color: #465364; }
    .oni-table tbody tr td.td-sans {
      font-family: OpenSans-Light;
      color: #586473; }
    .oni-table tbody tr td.td-address-string {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }

.oni-transperent-dialog {
  background-color: initial;
  box-shadow: initial; }
  .oni-transperent-dialog .header {
    color: #fff;
    font-size: 55px;
    line-height: 55px;
    padding: 66px 0 66px 66px; }
  .oni-transperent-dialog .content {
    background-color: #fff;
    padding: 50px 0 70px 0; }
  .oni-transperent-dialog .secondary {
    background-color: #f1f5fa;
    padding: 50px 0px 70px 0px; }
  .oni-transperent-dialog .title {
    font-size: 22px;
    line-height: 40px;
    color: #57677b; }
  .oni-transperent-dialog .footerButton {
    padding: 40px;
    background-color: #fff; }
    .oni-transperent-dialog .footerButton button {
      height: 50px;
      border-radius: 50px;
      background-color: #56bffd !important; }

.oni-autocomplete {
  height: 63px;
  margin: 20px 0 20px 0; }
  .oni-autocomplete input {
    height: 63px !important;
    padding-left: 10px;
    padding-right: 10px; }

.oni-autocomplete-for-wrap {
  height: 63px;
  font-family: OpenSans-Light;
  line-height: 63px;
  color: #33353b;
  font-size: 18px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }
  .oni-autocomplete-for-wrap input {
    height: 63px !important;
    font-family: OpenSans-Light;
    line-height: 63px;
    color: #33353b;
    font-size: 18px; }
  .oni-autocomplete-for-wrap input:not(.md-input) {
    height: 63px !important;
    font-family: OpenSans-Light;
    line-height: 63px;
    color: #33353b;
    font-size: 18px;
    padding: 0 10px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }
  .oni-autocomplete-for-wrap md-autocomplete-wrap {
    height: 63px;
    width: 100%;
    line-height: 63px;
    border: 1px solid #e9e9e8;
    font-family: OpenSans-Light;
    color: #33353b;
    font-size: 18px;
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.table-responsive-vertical {
  padding: 20px 12px;
  overflow-x: auto;
  overflow-y: hidden;
  min-width: 300px; }
  .table-responsive-vertical table {
    margin-bottom: 0;
    min-width: 100%;
    border: 1px solid #e1e1e1; }
    .table-responsive-vertical table thead tr th {
      padding: 10px;
      font-weight: 500;
      font-size: 13px;
      color: #9e9e9e;
      vertical-align: bottom;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      text-transform: uppercase;
      background-color: #f7f7f7;
      text-align: left; }
      .table-responsive-vertical table thead tr th.th-with-input {
        text-align: right; }
    .table-responsive-vertical table tbody tr {
      width: 100%;
      cursor: pointer; }
      .table-responsive-vertical table tbody tr:hover {
        background-color: #f7f7f7; }
    .table-responsive-vertical table tbody tr td {
      padding: 10px; }

.title-block {
  height: 54px;
  padding: 0 12px;
  background: #f9f9f9;
  border-top: 2px solid #000; }
  .title-block span.title {
    font-weight: bold; }

md-input-container.no-field-input-container {
  margin: 0;
  padding: 0;
  position: relative;
  top: 20px; }

.no-vertical-field {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.title-block {
  height: 65px;
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 24px; }
  .title-block span.title {
    color: #545454;
    font-weight: 500; }

.c-ws-table {
  width: 100%; }
  .c-ws-table thead {
    text-transform: uppercase;
    background-color: #f5f5f5;
    border-bottom: 1px solid #e1e1e1;
    line-height: 48px; }
  .c-ws-table th {
    font-size: 10pt;
    text-align: left;
    color: #7f7f7f;
    padding: 0 8px 0 8px; }
    .c-ws-table th:first-of-type {
      padding-left: 24px; }
  .c-ws-table td {
    padding: 16px 8px 16px 8px;
    border-bottom: 1px solid #e1e1e1;
    vertical-align: top;
    color: #282828;
    font-size: 14px; }
    .c-ws-table td:first-of-type {
      padding-left: 24px; }
    .c-ws-table td.text-right-align {
      text-align: right; }
  .c-ws-table tbody tr {
    background-color: #fff; }
    .c-ws-table tbody tr:hover {
      background-color: #f5f5f5; }

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.white-gray {
  color: #9E9E9E; }

md-autocomplete {
  background: #fafafa !important; }

md-autocomplete-wrap {
  box-shadow: none !important; }

.line-wrap {
  width: 100%;
  height: 2px;
  background: #e0e0e0;
  margin: 10px 0; }

.md-input[disabled], md-select[disabled] .md-select-value {
  color: #000 !important; }

.md-input[disabled] {
  background-position: bottom 0px left 0 !important; }

.input-new-item {
  margin-bottom: 0; }

.md-toolbar-tools h2 {
  color: #fff !important; }

.shipment-info {
  padding: 0 24px;
  margin: 24px 0 12px 0; }

.shipment-sort-icon {
  margin-left: 0;
  margin-right: 24px; }

.filter-wrap {
  padding: 10px 24px 0 24px;
  box-sizing: content-box; }
  .filter-wrap md-input-container {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
    .filter-wrap md-input-container .md-errors-spacer {
      display: none !important; }

.icon-clear-filter {
  margin-right: 14px !important;
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
  font-size: 18px;
  color: #757575; }

.ttn-filter {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  max-width: 150px !important; }
  .ttn-filter .md-errors-spacer {
    display: none !important; }

.comment-input {
  margin: 0 !important;
  border: 0 !important;
  padding: 0 !important; }
  .comment-input textarea, .comment-input input {
    line-height: 15px;
    border: 1px solid #e0e0e0 !important;
    border-radius: 3px;
    padding: 3px !important;
    box-sizing: content-box;
    margin-bottom: 5px; }
  .comment-input input {
    min-width: 55px; }
  .comment-input .md-errors-spacer {
    display: none !important; }
  .comment-input__debt input {
    line-height: 15px;
    border: 1px solid #e0e0e0 !important;
    border-radius: 3px;
    padding: 2px !important;
    box-sizing: content-box;
    margin-bottom: 5px;
    max-width: 60px !important;
    min-width: 60px !important; }
  .comment-input__comment textarea {
    max-width: 200px !important;
    min-width: 200px !important; }
  .comment-input__comment--large textarea {
    max-width: 300px !important;
    min-width: 300px !important; }
  .comment-input__kara input {
    max-width: 20px !important;
    min-width: 20px !important; }

.tr-control {
  background: #ffaca7; }
  .tr-control td {
    background: #ffaca7; }

.tr-select td {
  border-top: 3px solid #000 !important;
  border-bottom: 3px solid #000 !important; }

.date-input {
  margin-right: 30px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important; }
  .date-input .md-errors-spacer {
    display: none !important; }
  .date-input md-icon {
    margin-left: 120px !important; }

.info-input {
  margin: 20px 0 0 0 !important; }
  .info-input .md-errors-spacer {
    display: none !important; }

.control-filter {
  margin-top: 12px !important;
  margin-right: 30px !important; }

.shipment-parent-info {
  margin-left: 20px; }

.table-info {
  white-space: nowrap;
  margin-bottom: 4px; }
  .table-info__title {
    color: #9e9e9e; }

.select-user {
  margin: 15px 24px 5px 24px; }

.filter-icon {
  margin: 10px 20px 0 0; }
  .filter-icon--active {
    color: #dd2c00; }

.component-title {
  font-size: 20px;
  font-weight: 600;
  height: 50px;
  padding: 0 24px; }

.action-icon {
  margin: 0 24px 0 0; }

.dialog-content {
  padding: 24px; }

.simple-input {
  margin: 0 !important; }
  .simple-input--with-margin {
    margin: 10px 0 !important; }
  .simple-input .md-errors-spacer {
    display: none; }

.auth-input {
  margin: 0 0 24px 0 !important; }
  .auth-input .md-errors-spacer {
    display: none; }

md-tab-content > div {
  flex-direction: column;
  flex-grow: 1; }

.height-100 {
  height: 100%; }

.log-audit-icon {
  margin-left: 10px !important; }

.login-wrap {
  margin: 0 12px;
  padding: 24px;
  background: #f6f6f6;
  border-radius: 2px; }
  .login-wrap__title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 24px; }
  .login-wrap .auth-button {
    padding: 0 !important;
    margin: 0 0 24px 0 !important; }

.no-outline {
  outline: none; }
  .no-outline:active, .no-outline:focus {
    outline: none; }

.pagination-wrap, ws-pagination {
  min-height: 30px !important; }

input, textarea {
  border-bottom: 1px solid #aaa !important; }

.table-wrap {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: auto; }

.fix-table-header {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 50px;
  text-transform: uppercase;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e1e1e1;
  justify-content: start;
  align-items: center;
  z-index: 1; }
  .fix-table-header__item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    color: #757575;
    text-align: left;
    padding: 0 8px 0 8px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center; }
    .fix-table-header__item:first-of-type {
      padding-left: 24px; }

.c-ws-table {
  width: 100%;
  max-width: 100%; }
  .c-ws-table md-icon {
    max-height: 17px;
    min-height: inherit;
    font-size: 17px;
    color: #979797;
    min-height: 17px; }
  .c-ws-table thead {
    text-transform: uppercase;
    background-color: #f5f5f5;
    border-bottom: 1px solid #e1e1e1; }
  .c-ws-table th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    color: #757575;
    text-align: left;
    padding: 0 8px 0 8px;
    font-weight: 600; }
    .c-ws-table th:first-of-type {
      padding-left: 24px; }
  .c-ws-table td {
    font-size: 12px;
    color: #212121;
    padding: 16px 8px 16px 8px;
    border-bottom: 1px solid #e1e1e1;
    vertical-align: top; }
    .c-ws-table td:first-of-type {
      padding-left: 24px; }
    .c-ws-table td:last-of-type {
      padding-right: 24px; }
  .c-ws-table tbody tr {
    background-color: #fff; }
    .c-ws-table tbody tr:hover {
      background-color: #f5f5f5; }
    .c-ws-table tbody tr.select {
      background-color: #2196f3; }
  .c-ws-table--clicable tr {
    cursor: pointer; }

.span-a {
  color: #2196f3; }
  .span-a:hover {
    color: #2196f3;
    text-decoration: underline; }

md-select-menu .select-header {
  display: flex;
  position: relative;
  align-items: center;
  width: auto;
  height: 48px;
  padding-left: 10px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer; }
  md-select-menu .select-header input {
    width: 100%;
    height: 100%;
    padding: 0;
    border: 0;
    outline: none; }

* {
  box-sizing: border-box; }

html, body, div, span, applet, object, iframe, p, h1, h2, h3, h4, h5, h6, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

[role="button"] {
  cursor: pointer; }

p {
  margin: 0 0 10px; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  text-decoration: none; }

a:link {
  color: #2196f3;
  text-decoration: none; }

a:visited {
  color: #2196f3; }

a:hover {
  color: #0d47a1;
  text-decoration: underline; }

a:active {
  color: #2196f3; }

md-datepicker {
  background-color: transparent; }

.md-chips .md-chip .md-chip-remove {
  border-radius: 50%;
  background-color: #8C8C8C;
  font-size: 10px;
  width: 16px;
  height: 16px;
  top: 8px;
  right: 8px;
  position: absolute; }
  .md-chips .md-chip .md-chip-remove md-icon {
    color: #e6e6e6;
    width: 100%;
    height: 100%;
    border-radius: 50%; }

ul {
  list-style: none; }

.shipment-info {
  padding: 0 24px;
  margin: 24px 0 12px 0; }

.shipment-sort-icon {
  margin-left: 0;
  margin-right: 24px; }

.filter-wrap {
  padding: 10px 24px 0 24px;
  box-sizing: content-box; }
  .filter-wrap md-input-container {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
    .filter-wrap md-input-container .md-errors-spacer {
      display: none !important; }

.icon-clear-filter {
  margin-right: 14px !important;
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
  font-size: 18px;
  color: #757575; }
  .icon-clear-filter--input {
    margin-right: 0 !important;
    margin-left: 5px !important; }

.ttn-filter {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  max-width: 150px !important; }
  .ttn-filter .md-errors-spacer {
    display: none !important; }

.comment-input {
  margin: 0 !important;
  border: 0 !important;
  padding: 0 !important; }
  .comment-input textarea, .comment-input input {
    line-height: 15px;
    border: 1px solid #e0e0e0 !important;
    border-radius: 3px;
    padding: 3px !important;
    box-sizing: content-box;
    margin-bottom: 5px; }
  .comment-input input {
    min-width: 55px; }
  .comment-input .md-errors-spacer {
    display: none !important; }
  .comment-input--with-icon {
    position: relative; }
    .comment-input--with-icon textarea, .comment-input--with-icon input {
      padding-right: 10px !important; }
    .comment-input--with-icon md-icon {
      position: absolute;
      right: -10px !important;
      top: 50%;
      transform: translateY(-60%); }

.tr-control {
  background: #ffaca7; }
  .tr-control td {
    background: #ffaca7; }

.date-input {
  margin-right: 30px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important; }
  .date-input .md-errors-spacer {
    display: none !important; }
  .date-input md-icon {
    margin-left: 120px !important; }

.info-input {
  margin: 20px 0 0 0 !important; }
  .info-input .md-errors-spacer {
    display: none !important; }

.control-filter {
  margin-top: 12px !important;
  margin-right: 30px !important; }

.shipment-parent-info {
  margin-left: 20px; }

.table-info {
  white-space: nowrap;
  margin-bottom: 4px; }
  .table-info__title {
    color: #9e9e9e; }

.ch-tree .tree-item.selected {
  background-color: #C8EBFB;
  color: #335faf; }

.ch-tree .element {
  outline: inherit;
  padding: 10px;
  font-size: 15px;
  color: #000; }
  .ch-tree .element .title {
    width: 100%; }

.ch-tree .element-a {
  width: 100%;
  height: 40px;
  padding: 10px 10px 10px 20px; }
  .ch-tree .element-a .element {
    padding: 0; }
  .ch-tree .element-a:hover {
    text-decoration: none; }

.ch-tree .toggle-button {
  margin-right: 10px; }

.ws-pagination__pages {
  list-style-type: none; }
  .ws-pagination__pages li {
    display: inline-block;
    border-radius: 2px;
    text-align: center;
    vertical-align: top;
    height: 30px;
    box-sizing: 2px; }
  .ws-pagination__pages a {
    color: #444;
    font-size: 15px;
    padding: 0 10px;
    line-height: 30px;
    text-decoration: none; }

.ws-pagination__page--active {
  background-color: #0d47a1; }
  .ws-pagination__page--active a {
    color: #fff; }

.ws-pagination__page--disabled a {
  color: #9E9E9E; }

.ws-pagination__page--arrow-prev::after {
  content: '<';
  font-size: 25px; }

.ws-pagination__page--arrow-next::after {
  content: '>';
  font-size: 25px; }

.loader-inline {
  display: inline-block;
  vertical-align: middle; }
  .loader-inline .sk-circle {
    width: 20px;
    height: 20px;
    position: relative; }
    .loader-inline .sk-circle .sk-child {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0; }
      .loader-inline .sk-circle .sk-child:before {
        content: '';
        display: block;
        margin: 0 auto;
        width: 15%;
        height: 15%;
        background-color: #3F51B5;
        border-radius: 100%;
        animation: sk-circleBounceDelay 1.2s infinite ease-in-out both; }
    .loader-inline .sk-circle .sk-circle2 {
      transform: rotate(30deg); }
      .loader-inline .sk-circle .sk-circle2:before {
        animation-delay: -1.1s; }
    .loader-inline .sk-circle .sk-circle3 {
      transform: rotate(60deg); }
      .loader-inline .sk-circle .sk-circle3:before {
        animation-delay: -1s; }
    .loader-inline .sk-circle .sk-circle4 {
      transform: rotate(90deg); }
      .loader-inline .sk-circle .sk-circle4:before {
        animation-delay: -0.9s; }
    .loader-inline .sk-circle .sk-circle5 {
      transform: rotate(120deg); }
      .loader-inline .sk-circle .sk-circle5:before {
        animation-delay: -0.8s; }
    .loader-inline .sk-circle .sk-circle6 {
      transform: rotate(150deg); }
      .loader-inline .sk-circle .sk-circle6:before {
        animation-delay: -0.7s; }
    .loader-inline .sk-circle .sk-circle7 {
      transform: rotate(180deg); }
      .loader-inline .sk-circle .sk-circle7:before {
        animation-delay: -0.6s; }
    .loader-inline .sk-circle .sk-circle8 {
      transform: rotate(210deg); }
      .loader-inline .sk-circle .sk-circle8:before {
        animation-delay: -0.5s; }
    .loader-inline .sk-circle .sk-circle9 {
      transform: rotate(240deg); }
      .loader-inline .sk-circle .sk-circle9:before {
        animation-delay: -0.4s; }
    .loader-inline .sk-circle .sk-circle10 {
      transform: rotate(270deg); }
      .loader-inline .sk-circle .sk-circle10:before {
        animation-delay: -0.3s; }
    .loader-inline .sk-circle .sk-circle11 {
      transform: rotate(300deg); }
      .loader-inline .sk-circle .sk-circle11:before {
        animation-delay: -0.2s; }
    .loader-inline .sk-circle .sk-circle12 {
      transform: rotate(330deg); }
      .loader-inline .sk-circle .sk-circle12:before {
        animation-delay: -0.1s; }

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    transform: scale(0); }
  40% {
    transform: scale(1); } }

.loader-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2500;
  display: block; }
  .loader-wrapper .spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px; }
    .loader-wrapper .spinner > div {
      background-color: #3F51B5;
      height: 100%;
      width: 6px;
      display: inline-block;
      animation: sk-stretchdelay 1.2s infinite ease-in-out; }
    .loader-wrapper .spinner .rect2 {
      animation-delay: -1.1s; }
    .loader-wrapper .spinner .rect3 {
      animation-delay: -1.0s; }
    .loader-wrapper .spinner .rect4 {
      animation-delay: -0.9s; }
    .loader-wrapper .spinner .rect5 {
      animation-delay: -0.8s; }

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4); }
  20% {
    transform: scaleY(1); } }

.c-ch-tabs {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row;
  flex: 1; }
  .c-ch-tabs__menu {
    background-color: #fbfbfb;
    max-width: 200px; }
  .c-ch-tabs__menu-list {
    padding: 15px 0 15px 0;
    width: 100%; }
  .c-ch-tabs .c-ch-tabs__menu-list-item, .c-ch-tabs .c-ch-tabs__menu-list-item--active {
    padding: 10px 30px 10px 30px; }
  .c-ch-tabs__menu-list-item--active {
    color: #fff;
    background-color: #4474c9; }
  .c-ch-tabs__content {
    display: block;
    position: relative;
    overflow: auto;
    flex: 1; }

tr.ui-state-highlight {
  background: #f7f7f7;
  border: 2px dashed #e1e1e1;
  border-collapse: separate;
  border-radius: 4px;
  height: 61px;
  z-index: 100; }

.ui-sortable-helper {
  opacity: 0.6; }

.top-nav {
  padding: 10px 5px;
  background: #0d47a1 !important; }
  .top-nav .md-toolbar-tools {
    max-height: 100%;
    height: auto; }
    .top-nav .md-toolbar-tools .md-icon-button {
      min-width: 40px; }
  .top-nav__l-wrapper {
    display: block;
    position: relative;
    padding: 10px 0 10px 0; }
  .top-nav__header {
    float: left;
    white-space: nowrap;
    line-height: 40px; }
  .top-nav__actions {
    float: right;
    min-height: 40px; }
  .top-nav button.primary {
    background-color: #4caf50;
    color: #fff; }
    .top-nav button.primary:hover {
      background-color: #4caf50 !important; }

.top-nav-email {
  width: 100%;
  padding: 12px; }

.td-button-width {
  width: 145px; }
