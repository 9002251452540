/**
 *  Do not remove the comment below. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
// endbower
// injector
@import "modules/global/global.scss";
@import "modules/admin/styles/mainPage.scss";
@import "modules/admin/styles/shipment.scss";
@import "modules/auth/styles/authLogin.scss";
@import "modules/main/styles/main.scss";
@import "modules/main/styles/reset.scss";
@import "modules/user/styles/mainPage.scss";
@import "modules/user/styles/shipment.scss";
@import "modules/global/components/chTree/chTree.scss";
@import "modules/global/components/wsPagination/wsPagination.scss";
@import "modules/global/directive/chSpinner/chSpinner.scss";
@import "modules/global/directive/chTabs/chTabs.scss";
@import "modules/global/directive/wsSortable/wsSortable.scss";
@import "modules/main/components/topNav/topNav.scss";
// endinjector

.td-button-width {
  width: 145px;
}
